<template>
  <div class="terms">
    <div class="container">
      <h2 class="terms__title">{{ $t("footer.terms") }}</h2>
      <div class="terms__content">
        <b-card
          no-body
          class="item"
          v-for="(item, index) in items"
          :key="index"
        >
          <a
            class="item__heading mb-2"
            role="tab"
            v-b-toggle="`note-collapse-${index}`"
            variant="link"
          >
            <span>{{ item.question }}</span>
            <img
              src="/img/icon-arrow-down.svg"
              alt="arrow-down"
              class="rotage-down"
            />
          </a>
          <b-collapse
            :id="`note-collapse-${index}`"
            visible
            accordion="my-accordion"
            role="tabpanel"
          >
            <div class="item__content">
              <div v-html="item.answer" />
            </div>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { termsEn, termsVi } from "@/assets/i18n/terms.js";
export default {
  computed: {
    items() {
      const lang = this.$i18n.locale.toUpperCase();
      return lang == "EN" ? termsEn : termsVi;
    },
  },
};
</script>
<style scoped lang="scss">
.terms {
  min-height: 100vh;
  position: relative;
  .container {
    padding: 64px 0;
    text-align: center;
  }
  &__title {
    color: #ffffff;
    font-family: "Overpass";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    margin: 0;
    margin-bottom: 16px;
  }
  &__description {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #adaeb2;
    margin-bottom: 64px;
  }
  &__content {
    .item {
      background: #20242d;
      border: 1px solid #43464e;
      border-radius: 8px;
      padding: 12px 16px;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      margin-bottom: 24px;
      &__heading {
        &:hover,
        &:focus {
          text-decoration: unset;
        }
        display: flex;
        justify-content: space-between;
        span {
          color: #ffffff;
          font-weight: 700;
        }
      }
      &__content {
        color: #adaeb2;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        p {
          margin-top: 16px;
        }
      }
      .rotage-down {
        transform: rotate(180deg);
      }
    }
  }
  .collapsed > .rotage-down {
    transform: rotate(180deg);
  }
  .not-collapsed > .rotage-down {
    transform: rotate(360deg);
  }
  @media only screen and (max-width: 992px) {
    padding: 12px;
  }
}
</style>
