<template>
  <div v-if="totalUser" class="">
    <p class="text-white mb-0 text-center small">{{ $t('total-member') }}</p>
    <div class="flex-wrap text-center ">
      <div class=" d-flex">
        <div class="clock" v-for="(n, index) in totalUser" :key="index">
          <div class="flipper flipping overflow-hidden" v-for="(item, ind) in n" :key="ind + 'xx'">
            <div class="gear"></div>
            <div class="gear"></div>
            <div class="top">
              <div class="text-home_countdowns">{{ item }}</div>
            </div>
            <div class="bottom">
              <div class="text-home_countdowns">{{ item }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import chunk from 'lodash.chunk'
import AnimatedNumber from "./AnimatedNumber.vue";

export default {
  components: { AnimatedNumber },
  computed: {
    ...mapGetters([
      "Auth/staticUser",
    ]),
    totalUser() {
      if (!this.$store.getters["Auth/staticUser"]) {
        return undefined
      }
      let totalUser =  this.$store.getters["Auth/staticUser"].filter(function(item) {
        return item.Country === "Total";
      });
      const totalArray = totalUser[0].Count.toString().split('');
      console.log(totalArray);
      return this.chunkRight(totalArray, 3)
    }
  },
  mounted() {
    this['Auth/getStatisticUser']()
  },
  methods: {
    chunkRight (arr, size) {
    const rm = arr.length % size;
      return rm ?
        [arr.slice(0, rm), ...chunk(arr.slice(rm), size)]
        :
        chunk(arr, size);
    },
    ...mapActions(["Auth/getStatisticUser"]),
  }
};
</script>

<style lang="scss" scoped>

:root {
  --bgcolor: rgb(38, 37, 41);
  --clockheight: 70px;
  --radius: 05px;
  --rotationtime: 1.2s;
}

.clock {
  display: flex;
  padding: 0 2px;
  height: 60px;
  border-radius: 05px;
}
.flipper {
  position: relative;
  width: 30px;
  height: 100%;
  margin: 0 2px;
}
.gear {
  position: absolute;
  top: calc(40px / 2.5);
  width: 4px;
  height: calc(70px / 6);
  background: linear-gradient(
    to bottom,
    #000000 0%,
    #666666 17%,
    #7f7f7f 52%,
    #7f7f7f 52%,
    #0c0c0c 53%,
    #595959 87%,
    #131313 100%
  );
  outline: 3px solid #000;
  z-index: 99;
  transform-style: preserve-3d;
  transform: translateZ(10px);
  perspective: 0;
  left: -1px;
}
.gear:nth-child(2) {
  left: calc(100% - 3px);
}
.top,
.bottom {
  border-top: 2px solid rgb(102, 103, 110);
  border-bottom: 2px solid #000;
}
.top {
  position: relative;
  width: 100%;
  height: calc(50% - 10px);
  background-image: linear-gradient(rgb(48, 49, 53) 0%, rgb(56, 57, 62) 100%);
  // margin-top: 10px;
  border-top-left-radius: calc(05px * 0.65);
  border-top-right-radius: calc(05px  * 0.65);
  border-bottom: 0;
}
.bottom {
  position: relative;
  width: 100%;
  height: calc(50% - 10px);
  background-image: linear-gradient(rgb(57, 58, 63) 0%, rgb(65, 65, 71) 100%);
  border-bottom-left-radius: calc(05px * 0.65);
  border-bottom-right-radius: calc(05px * 0.65);
}
.text-home_countdowns {
  font-size: 25px;
  display: block;
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  line-height: 40px;
  text-align: center;
  font-weight: 500;
  color: #fff;
}
.bottom .text-home_countdowns {
  line-height: 0;
}
.top.new,
.bottom.new {
  position: absolute;
  left: 0;
  z-index: 12;
}
.top.new {
  top: -05px;
  z-index: 1;
}
.top.new .text-home_countdowns {
  backface-visibility: hidden;
}
.bottom.new {
  position: absolute;
  top: 0;
  height: calc(100% - 0px);
  transform: rotateX(0.5turn);
  z-index: 1;
  opacity: 0;
}
.flipper.flipping .top.new {
  transform-origin: bottom center;
  animation: rotate 1.2s 1 ease-in-out forwards;
}
.flipper.flipping .bottom.new {
  animation: rotatebottom 1.2s 1 ease-in-out forwards;
}
@keyframes rotatebottom {
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rotate {
  0% {
    transform: rotateX(0) translateY(0px);
  }
  100% {
    transform: rotateX(-180deg) translateY(-10px);
  }
}
</style>
