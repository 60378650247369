<template>
  <BaseModal :config="config" @hidden="resetData">
    <template slot="content">
      <div class="receive__title" v-if="data.field">
        {{ $t(`users.infor.${data.field.toLowerCase()}`) }}
      </div>
      <div class="receive__content">
        <template v-if="data.field == 'Country'">
          <BaseSelect
            ref="country"
            :input-value="formData"
            name="country"
            :label="$t('users.infor.country')"
            :items="countries"
            text-field="name"
            value-field="name"
            :is-show-default="false"
            key-loop="index"
            class-name="theme-light"
            @change="formData = $event"
          />
        </template>
        <BaseDatePicker
          v-else-if="data.field == 'BirthDay'"
          id="birthday"
          ref="birthday"
          class="theme-light mb-3"
          :value="formData"
          @input="formData = $event"
        />
        <template v-else-if="data.field == 'BusinessDescription'">
          <BaseInputCustom
            class-name="theme-light"
            ref="reason"
            v-model="formData"
            type="textarea"
            name="Reason"
            required
            :label="
              data.field ? $t(`users.infor.${data.field.toLowerCase()}`) : ''
            "
          />
        </template>
        <template v-else>
          <BaseInputCustom
            class="theme-light"
            class-name="confirmation-email__email"
            v-model="formData"
            type="email"
            required
            :label="
              data.field ? $t(`users.infor.${data.field.toLowerCase()}`) : ''
            "
          />
        </template>
        <template v-if="(data.field == 'Email' || data.field == 'Username')">

          <BaseInputCustom
            class-name="theme-light"
            ref="pin"
            v-model="Pin"
            is-pin
            name="pin"
            inputmode="numeric"
            required
            label="Security PIN"
            error-required="Please enter Security PIN"
          />
          <p class="text-note">
            {{ $t("stock.pin.first")
            }}<router-link
              tag="a"
              :to="'/profile/security?tab=pin'"
              target="_blank"
              style="color: #007bff; font-weight: bold"
              >{{ $t("stock.pin.second") }}</router-link
            >{{ $t("stock.pin.third") }}
          </p>
          <div class="withdraw__content">
            <p class="text-note">
              <span class="text-danger">{{ $t('user.note') }}</span>
              <i>
                {{ $t('change-info.email.note', [$t(`contact.${data.field}`)]) }}
              </i>
            </p>
            <BaseCheckbox v-model="isValid" :inline="true" class="agree">
              <span class="label">
                {{ $t('change-info.confirm') }}
              </span>
            </BaseCheckbox>
          </div>
        </template>
      </div>
      <div class="d-flex justify-content-around mt-3">
        <b-button @click="onClose()" variant="light default">{{
          $t("action.cancel")
        }}</b-button>
        <button
          class="btn btn-primary"
          @click="updateInfo"
          :class="{ 'button--loading': loading, disabled: !formData }"
          style="min-width: 100px"
        >
          <span>{{ $t("action.update") }}</span>
        </button>
      </div>
    </template>
  </BaseModal>
</template>

  <script>
import { MODAL, NOTIFICATION_ICON } from "@/constants";
import BaseSelect from "@components/Inputs/BaseSelect";
import BaseInputCustom from "@components/Inputs/BaseInputCustom";
import countries from "@/constants/countries.json";
const { RepositoryFactory } = require("@apis/repositoryFactory");
const UserRepository = RepositoryFactory.get("user");
import clonedeep from "lodash.clonedeep";
import { getCurrentUser } from "@/core/utils";
import BaseDatePicker from "@/components/Inputs/BaseDatePicker.vue";
import validateMixin from "@/core/mixins/validate";
import BaseCheckbox from "@/components/Inputs/BaseCheckbox.vue"

export default {
  name: "ModalUpdateInfor",
  components: {
    BaseSelect,
    BaseDatePicker,
    BaseCheckbox,
    BaseInputCustom,
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        value: "",
        field: "Update Infor",
      }),
    },
  },
  mixins: [validateMixin],
  data() {
    return {
      isValid: false,
      countries: countries,
      formData: "",
      Pin: "",
      config: {
        id: MODAL.UPDATE_INFO,
        class: "modal--update",
        noCloseOnBackDrop: false,
        centered: true,
        theme: "light",
      },
      info: {},
      loading: false,
    };
  },
  mounted() {},
  watch: {
    "data.value": {
      handler(value) {
        if (value) {
          this.formData = value;
        } else this.formData = "";
      },
      immediate: true,
    },
  },
  methods: {
    onClose() {
      this.$bvModal.hide(this.config.id);
    },
    resetData() {
      this.isValid = false;
    },
    async updateInfo() {

      const refs = ["pin"];
      const refsValid = this.$_validateMixin_refs(refs);
      if ( !refsValid && (this.data.field == "Email" || this.data.field == "Username")) return
      if (!this.isValid && (this.data.field == "Email" || this.data.field == "Username")) {
        this.$alertMsg('fail', this.$t('validate.isconfirm'))
        return
      }
      this.loading = true;
      try {
        const currentUser = getCurrentUser();
        this.info = clonedeep(currentUser, true);
        if (this.info[this.data.field] == this.formData) {
          this.isValid = false;
          this.$bvModal.hide(this.config.id);
        }
        this.info[this.data.field] = this.formData;
        if (this.data.field == "Email" || this.data.field == "Username") {
          this.info.Pin = this.Pin
        }
        const data = await UserRepository.updateInformation(this.info);
        this.$store.commit("Auth/updateCurrentUser", {
          ...currentUser,
          ...data,
        });

        if (this.data.field == "Email" && (currentUser.Email != this.formData)) {
          this.$notifyPopup(NOTIFICATION_ICON.SUCCESS,
            this.$t('swal.title.sent'),
            this.$t('security-email.sent'),
            {
              action1Text: 'OK',
              action1Func: () => {}
            }
          )
        } else if (this.data.field == "Username" && (currentUser.Username != this.formData)) {
          this.$notifyPopup(NOTIFICATION_ICON.SUCCESS,
            this.$t('swal.title.sent'),
            this.$t('security-email.sent'),
            {
              action1Text: 'OK',
              action1Func: () => {}
            }
          )
        } else {
          this.$alertMsg("success", this.$t("swal.success.saved"));
        }
        this.isValid = false;
        this.Pin = ''
        this.$bvModal.hide(this.config.id);
      } catch (e) {
        this.$alertMsg("fail", e.response.data.message);
      }
      this.loading = false;
    },
  },
};
</script>

  <style scoped lang="scss">

.agree {
    &.form-check {
      padding-left: 0;
      &::v-deep {
        .form-check-label {
          .form-check-sign::before {
            color: #F7F7F7;
            font-size: 24px;
            background: #F7F7F7;
            border-radius: 4px;
            height        : 24px;
            width         : 24px;
            margin-left: -35px;
            margin-top: -18px;
            border: 1px solid #afafaf;
          }
          .form-check-sign::after {
            content         : '\2713';
            color: #007BFF;
            height        : 24px;
            width         : 24px;
            font-size: 24px;
            margin-left: -35px;
            margin-top: -18px;
          }
        }
      }

    }
    .label {
      color: #000000;
    }
  }
.receive {
  width: 407px;
  color: #ffffff;
  margin-top: 32px;
  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    color: #141822;
    text-align: center;
  }
  &__content {
    margin-top: 24px;
    .qr-img {
      width: 100%;
      border: 1px solid #adaeb2;
      border-radius: 8px;
      padding: 1rem;
      /deep/ canvas {
        width: 100%;
      }
    }
    .icon-save {
      margin-top: 24px;
      display: flex;
      justify-content: center;
      a {
        background: #f7f7f7;
        padding: 18px;
        border-radius: 100%;
        img {
          width: 28px;
        }
      }
    }
  }
}
::v-deep .base-select {
  margin-bottom: 20px;
  &__label {
    color: #43464e;
  }
}
::v-deep .base-select__wrap {
  background: #f7f7f7;
  .arrow-down {
    color: #43464e;
  }
}
::v-deep .base-select__inner {
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #141822;
  }
}
/deep/ .base-select__options {
  background: #ffffff;
  .base-select__item {
    position: relative;
    &.active {
      position: relative;
      &::before {
        display: block;
        content: "";
        width: 20px;
        height: 20px;
        background-image: url("/img/wallet/icon-check.svg");
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
      }
    }
    &:hover {
      background: #f7f7f7;
      color: #101828;
      text-decoration: none;
    }
    .base-select__link {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #101828;
      border: none;
    }
  }
}
/deep/ .btn.disabled {
  pointer-events: none !important;
}
</style>
