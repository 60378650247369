<template>
  <div class="info">
    <div class="info__header mt-4 d-flex align-items-center">
      <div class="info__avatar mr-3">
        <img :src="avatarPath" alt="avatar" class="avatar mr-2" />
        <input
          id="uploadAvatar"
          type="file"
          class="d-none"
          :accept="acceptType"
          @change="uploadFile"
          ref="file"
        />
        <label for="uploadAvatar" class="upload-button">
          <i aria-hidden="true" class="fa fa-camera" />
        </label>
      </div>
      <span class="info__name truncate mw-120px mx-2"
        >{{ `${formData.FirstName} ${formData.LastName}` }}
      </span>
      <router-link
        to="/profile/kyc"
        class="not-verified d-flex align-items-center"
        v-if="!isKYC"
      >
        <i class="fa fa-exclamation-circle text-danger" />
        <span> {{ $t("users.infor.not-verified") }} </span>
      </router-link>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4 mt-4">
        <p class="profile_section-title">{{ $t("profile.overview.Basic") }}</p>
        <b-card class="profile_section-card">
          <div class="d-flex justify-content-between">
            <p>{{ $t("profile.overview.VLink") }}:</p>
            <p class="d-flex">
              {{ currentUser.VLinkId }}
            </p>
          </div>
          <div class="d-flex justify-content-between">
            <p>{{ $t("profile.overview.Username") }}:</p>
            <p class="d-flex">
              {{ currentUser.Username }}
              <img
                src="/assets/img/icon/edit.svg"
                class="icon-edit-info"
                @click="
                  () => {
                    updateInfoModal({
                      field: 'Username',
                      value: currentUser.Username,
                    });
                  }
                "
              />
            </p>
          </div>
          <div class="d-flex justify-content-between">
            <p>{{ $t("profile.overview.BirthDay") }}:</p>
            <p class="d-flex">
              <span v-if="currentUser.BirthDay">{{
                currentUser.BirthDay | formatDateNoOffset
              }}</span>
              <img
                src="/assets/img/icon/edit.svg"
                class="icon-edit-info"
                @click="
                  () => {
                    updateInfoModal({
                      field: 'BirthDay',
                      value: currentUser.BirthDay,
                    });
                  }
                "
              />
            </p>
          </div>
          <div class="d-flex justify-content-between">
            <p>{{ $t("users.infor.first-name") }}:</p>
            <p class="d-flex">
              <span class="text-elipssis" v-b-tooltip.hover :title="currentUser.FirstName || ''">{{ currentUser.FirstName }}</span>
              <img
                src="/assets/img/icon/edit.svg"
                class="icon-edit-info"
                @click="
                  () => {
                    updateInfoModal({
                      field: 'FirstName',
                      value: currentUser.FirstName,
                    });
                  }
                "
              />
            </p>
          </div>
          <div class="d-flex justify-content-between">
            <p>{{ $t("users.infor.last-name") }}:</p>
            <p class="d-flex">
              <span class="text-elipssis" v-b-tooltip.hover :title="currentUser.LastName || ''">{{ currentUser.LastName }}</span>
              <img
                src="/assets/img/icon/edit.svg"
                class="icon-edit-info"
                @click="
                  () => {
                    updateInfoModal({
                      field: 'LastName',
                      value: currentUser.LastName,
                    });
                  }
                "
              />
            </p>
          </div>
          <div class="d-flex justify-content-between">
            <p>{{ $t("profile.overview.Title") }}:</p>
            <p class="d-flex">
              {{ currentUser.Title || "" }}
            </p>
          </div>
          <div class="d-flex justify-content-between">
            <p>{{ $t("profile.overview.Type") }}:</p>
            <p class="d-flex">
              {{ currentUser.Type || "" }}
            </p>
          </div>
        </b-card>
      </div>

      <div class="col-12 col-md-6 col-lg-4 mt-4">
        <p class="profile_section-title">
          {{ $t("profile.overview.Contact") }}
        </p>
        <b-card class="profile_section-card">
          <div class="d-flex justify-content-between">
            <p>{{ $t("profile.overview.Email") }}:</p>
            <p class="d-flex">
              <span class="text-elipssis" v-b-tooltip.hover :title="currentUser.Email || ''">{{ currentUser.Email }}</span>
              <img
                src="/assets/img/icon/edit.svg"
                class="icon-edit-info"
                @click="
                  () => {
                    updateInfoModal({
                      field: 'Email',
                      value: currentUser.Email,
                    });
                  }
                "
              />
            </p>
          </div>
          <div class="d-flex justify-content-between">
            <p>{{ $t("profile.overview.Phone") }}:</p>
            <p class="d-flex">
              <span>
                <!-- <template v-if="currentUser.DialCode">
                  ({{ currentUser.DialCode || "N/A" }})
                </template> -->
                {{ currentUser.PhoneNumber || "" }}
              </span>
              <img
                src="/assets/img/icon/edit.svg"
                class="icon-edit-info"
                @click="
                  () => {
                    updateInfoModal({
                      field: 'PhoneNumber',
                      value: currentUser.PhoneNumber,
                    });
                  }
                "
              />
            </p>
          </div>
          <div class="d-flex justify-content-between">
            <p>{{ $t("users.infor.dial-code") }}:</p>
            <p class="d-flex">
              <span>
                {{ currentUser.DialCode || "" }}
              </span>
              <img
                src="/assets/img/icon/edit.svg"
                class="icon-edit-info"
                @click="
                  () => {
                    updateInfoModal({
                      field: 'DialCode',
                      value: currentUser.DialCode,
                    });
                  }
                "
              />
            </p>
          </div>
        </b-card>
      </div>

      <div class="col-12 col-md-6 col-lg-4 mt-4">
        <p class="profile_section-title">
          {{ $t("profile.overview.Referral") }}
        </p>
        <b-card class="profile_section-card">
          <div class="d-flex justify-content-between">
            <p>{{ $t("profile.overview.VLink") }}:</p>
            <p class="d-flex">
              {{ currentUser.ReferralId || "" }}
            </p>
          </div>
          <div class="d-flex justify-content-between">
            <p>{{ $t("profile.overview.Username") }}:</p>
            <p class="d-flex">
              <span>
                {{ currentUser.ReferralUserName || "" }}
              </span>
            </p>
          </div>
          <div class="d-flex justify-content-between">
            <p>{{ $t("profile.overview.Email") }}:</p>
            <p class="d-flex" v-b-tooltip.hover :title="currentUser.ReferralEmail || ''">
              <span class="text-elipssis">
                {{ currentUser.ReferralEmail || "" }}
              </span>
            </p>
          </div>
          <div class="d-flex justify-content-between">
            <p>{{ $t("profile.overview.Phone") }}:</p>
            <p class="d-flex">
              <span>
                <template v-if="currentUser.ReferralDialCode">
                  ({{ currentUser.ReferralDialCode || "N/A" }})
                </template>
                {{ currentUser.ReferralPhoneNumber || "N/A" }}
              </span>
            </p>
          </div>
        </b-card>
      </div>

      <div class="col-12 col-md-6 col-lg-4 mt-4">
        <p class="profile_section-title">
          {{ $t("profile.overview.AddressDetails") }}
        </p>
        <b-card class="profile_section-card">
          <div class="d-flex justify-content-between">
            <p class="text-nowrap">{{ $t("profile.overview.Country") }}:</p>
            <p class="d-flex">
              {{ currentUser.Country || "" }}
              <img
                src="/assets/img/icon/edit.svg"
                class="icon-edit-info"
                @click="
                  () => {
                    updateInfoModal({
                      field: 'Country',
                      value: currentUser.Country,
                    });
                  }
                "
              />
            </p>
          </div>
          <div
            class="d-flex justify-content-between"
            v-if="currentUser.Country === 'United States'"
          >
            <p>{{ $t("profile.overview.Social") }}:</p>
            <p class="d-flex">
              <span>
                {{ currentUser.SocialSecurityCode || "" }}
              </span>
              <img
                src="/assets/img/icon/edit.svg"
                class="icon-edit-info"
                @click="
                  () => {
                    updateInfoModal({
                      field: 'SocialSecurityCode',
                      value: currentUser.SocialSecurityCode,
                    });
                  }
                "
              />
            </p>
          </div>
          <div class="d-flex justify-content-between">
            <p>{{ $t("profile.overview.City") }}:</p>
            <p class="d-flex">
              {{ currentUser.City || "" }}
              <img
                src="/assets/img/icon/edit.svg"
                class="icon-edit-info"
                @click="
                  () => {
                    updateInfoModal({ field: 'City', value: currentUser.City });
                  }
                "
              />
            </p>
          </div>
          <div class="d-flex justify-content-between">
            <p>{{ $t("profile.overview.State") }}:</p>
            <p class="d-flex">
              {{ currentUser.State || "" }}
              <img
                src="/assets/img/icon/edit.svg"
                class="icon-edit-info"
                @click="
                  () => {
                    updateInfoModal({
                      field: 'State',
                      value: currentUser.State,
                    });
                  }
                "
              />
            </p>
          </div>
          <div class="d-flex justify-content-between" v-if="!isBussinessType">
            <p class="text-nowrap mr-2">
              {{ $t("profile.overview.Address") }}:
            </p>
            <p class="d-flex text-right">
              {{ currentUser.Address || "" }}
              <img
                src="/assets/img/icon/edit.svg"
                class="icon-edit-info"
                @click="
                  () => {
                    updateInfoModal({
                      field: 'Address',
                      value: currentUser.Address,
                    });
                  }
                "
              />
            </p>
          </div>
          <div class="d-flex justify-content-between">
            <p>{{ $t("profile.overview.Zip") }} Code:</p>
            <p class="d-flex">
              {{ currentUser.ZipCode || "" }}
              <img
                src="/assets/img/icon/edit.svg"
                class="icon-edit-info"
                @click="
                  () => {
                    updateInfoModal({
                      field: 'ZipCode',
                      value: currentUser.ZipCode,
                    });
                  }
                "
              />
            </p>
          </div>
        </b-card>
      </div>

      <template v-if="isBussinessType">
        <div class="col-12 col-md-6 col-lg-4 mt-4">
          <p class="profile_section-title">
            {{ $t("profile.overview.Business") }}
          </p>
          <b-card class="profile_section-card">
            <div class="d-flex justify-content-between">
              <p>{{ $t("profile.overview.BusinessName") }}:</p>
              <p class="d-flex">
                {{ currentUser.BusinessName || "" }}
                <img
                  src="/assets/img/icon/edit.svg"
                  class="icon-edit-info"
                  @click="
                    () => {
                      updateInfoModal({
                        field: 'BusinessName',
                        value: currentUser.BusinessName,
                      });
                    }
                  "
                />
              </p>
            </div>
            <div class="d-flex justify-content-between">
              <p>{{ $t("profile.overview.Website") }}:</p>
              <p class="d-flex">
                <a :href="getHrefURL(currentUser.BusinessWebsite)">{{
                  currentUser.BusinessWebsite || ""
                }}</a>
                <img
                  src="/assets/img/icon/edit.svg"
                  class="icon-edit-info"
                  @click="
                    () => {
                      updateInfoModal({
                        field: 'BusinessWebsite',
                        value: currentUser.BusinessWebsite,
                      });
                    }
                  "
                />
              </p>
            </div>
            <div>
              <div class="d-flex justify-content-between">
                <p>{{ $t("profile.overview.BusinessDescription") }}:</p>
                <p class="d-flex">
                  <img
                    src="/assets/img/icon/edit.svg"
                    class="icon-edit-info"
                    @click="
                      () => {
                        updateInfoModal({
                          field: 'BusinessDescription',
                          value: currentUser.BusinessDescription,
                        });
                      }
                    "
                  />
                </p>
              </div>
              <span v-html="currentUser.BusinessDescription" />
            </div>
            <div class="mt-4">
              <div class="d-flex justify-content-between">
                <p>
                  {{ $t("business.logo") }}:
                  <span> ({{ $t("validate.upload-info") }}) </span>
                </p>
                <p class="d-flex">
                  <input
                    type="file"
                    id="uploadBusinessLogo"
                    :accept="acceptType"
                    class="d-none"
                    @change="uploadBusinessLogo"
                    ref="uploadBusinessLogo"
                  />
                  <label for="uploadBusinessLogo" class="upload-button">
                    <img
                      src="/assets/img/icon/edit.svg"
                      class="icon-edit-info"
                    />
                  </label>
                </p>
              </div>
              <img
                v-if="currentUser.BusinessLogo"
                :src="fixImageUrl(currentUser.BusinessLogo)"
                class="headers__logo__img mw-100 float-right"
                onerror="this.onerror=null;this.src='/assets/Default.jpg';"
              />
              <span v-else class="w-50 card-subtitle truncate"></span>
            </div>
          </b-card>
        </div>

        <div class="col-12 col-md-6 col-lg-4 mt-4">
          <p class="profile_section-title">
            {{ $t("business.location") }}
          </p>
          <b-card class="profile_section-card">
            <div class="row justify-content-end mb-4 m-0">
              <BaseInputCustom
                ref="address"
                type="text"
                v-model="formData.Address"
                class="theme-light w-100"
                required
                :error-required="$t('validate.required', {0: $t('business.location')})"
              />
              <div>
                <button
                  @click.prevent="updateMapLocation"
                  class="btn mt-2 btn-primary default"
                >
                  {{ $t("users.infor.update-map") }}
                </button>
                <button
                  @click.prevent="saveMapLocation"
                  class="btn mt-2 ml-2 btn-outline-primary default"
                >
                  {{ $t("action.submit") }}
                </button>
              </div>
            </div>
            <div class="">
              <Map
                v-if="currentUser.BusinessLatitude"
                :location="location"
                :key="formData.BusinessLatitude"
              />
              <h6 v-else class="w-50 card-subtitle truncate"></h6>
            </div>
          </b-card>
        </div>
      </template>
    </div>
    <ModalUpdateInfor :data="data" />
  </div>
</template>

<script>
import BaseSelect from "@components/Inputs/BaseSelect";
import BaseInputCustom from "@/components/Inputs/BaseInputCustom.vue";
import BaseDatePicker from "@/components/Inputs/BaseDatePicker.vue";
import clonedeep from "lodash.clonedeep";
import countries from "@/constants/countries.json";
import validateMixin from "@/core/mixins/validate";
import { fixUrl, getCurrentUser } from "@/core/utils";
import { apiImageUrl } from "@/constants/config";
const { RepositoryFactory } = require("@apis/repositoryFactory");
const UserRepository = RepositoryFactory.get("user");
const AssetRepository = RepositoryFactory.get("asset");
import ModalUpdateInfor from "./modal-update.vue";
import { MODAL } from "@/constants";
import Map from "@/pages/Maps.vue";
import gmapsInit from "@/core/utils/gmaps";
import BaseInputFile from "@components/Inputs/BaseInputFile";
export default {
  name: "Info",
  mixins: [validateMixin],
  components: {
    BaseSelect,
    BaseInputCustom,
    BaseDatePicker,
    ModalUpdateInfor,
    Map,
    BaseInputFile,
  },
  data() {
    return {
      MODAL,
      limitFileSize: 2097152,
      acceptType: [".jpg", ".jpeg", ".png", ".webp"],
      formData: {
        Address: "",
        BirthDay: "",
        BusinessDescription: "",
        BusinessLatitude: null,
        BusinessLogo: "",
        BusinessLongitude: null,
        BusinessName: "",
        BusinessWebsite: "",
        City: "",
        Country: "",
        DialCode: "",
        Email: "",
        FirstName: "",
        LastName: "",
        PhoneNumber: "",
        SocialSecurityCode: "",
        State: "",
        ZipCode: "",
      },
      dialCodes: [
        {
          name: "+123",
        },
        {
          name: "+84",
        },
      ],
      countries,
      data: {},
    };
  },
  mounted() {
    this.formData = clonedeep(this.currentUser, true);
    this.formData.Address = this.formData.Address ? this.formData.Address : ''
  },
  computed: {
    avatarPath() {
      return this.currentUser && this.currentUser.Avatar
        ? apiImageUrl + this.currentUser.Avatar
        : "/img/header/avatar.png";
    },
    isKYC() {
      return this.currentUser && this.currentUser.IsKYC;
    },
    isBussinessType() {
      return this.currentUser.Type === "BUSINESS";
    },
    location() {
      return {
        lng: this.formData.BusinessLongitude,
        lat: this.formData.BusinessLatitude,
      };
    },
  },
  methods: {
    isWrongFileFormat(file) {
      if (!file) {
        return true;
      }
      const str = file.name;
      const extension = str.substr(str.lastIndexOf("."));
      if (!extension) {
        return true;
      }
      return !this.acceptType.includes(extension.toLowerCase());
    },
    isWrongLimitFileSize(file) {
      return file.size > this.limitFileSize;
    },
    async updateMapLocation() {
      let address = this.formData.Address;
      var that = this;
      try {
        const google = await gmapsInit();
        const geocoder = new google.maps.Geocoder();

        geocoder.geocode({ address }, (results, status) => {
          if (status !== "OK" || !results[0]) {
            throw new Error(status);
          }
          this.formData.Address = results[0].formatted_address;
          this.formData.BusinessLatitude = results[0].geometry.location.lat();
          this.formData.BusinessLongitude = results[0].geometry.location.lng();
        });
      } catch (error) {
        console.error(error);
      }
    },
    updateInfoModal(item) {
      this.data = {
        field: item.field,
        value: item.value || "",
      };
      this.$bvModal.show(MODAL.UPDATE_INFO);
    },
    getHrefURL(url) {
      if (url == "N/A") return "#";
      return !url || url.indexOf("//") > -1 ? url : "//" + url;
    },
    fixImageUrl(url) {
      return fixUrl(url);
    },
    async uploadBusinessLogo() {
      const file = this.$refs.uploadBusinessLogo.files[0];
      if (!file) {
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      if (this.isWrongLimitFileSize(file) || this.isWrongFileFormat(file)) {
        this.$alertMsg("fail", this.$t("validate.upload-info"));
        return;
      }
      try {
        const { Photo } = await AssetRepository.upload(formData);
        const currentUser = getCurrentUser();
        currentUser.BusinessLogo = Photo;
        const data = await UserRepository.updateInformation(currentUser);
        this.$store.commit("Auth/updateCurrentUser", {
          ...currentUser,
          ...data,
        });
        this.$alertMsg("success", this.$t("swal.success.saved"));
      } catch (e) {
        this.$alertMsg("fail", e.message);
      }
    },
    async saveMapLocation() {
      const refs = ['address']
      const refsValid = this.$_validateMixin_refs(refs)
      if (!refsValid) return
      try {
        const currentUser = getCurrentUser();
        currentUser.Address = this.formData.Address
        const data = await UserRepository.updateInformation(currentUser);
        this.$store.commit("Auth/updateCurrentUser", {
          ...currentUser,
          ...data,
        });
        this.$alertMsg("success", this.$t("swal.success.saved"));
      } catch (e) {
        this.$alertMsg("fail", e.message);
      }
    },
    async uploadFile() {
      const file = this.$refs.file.files[0];
      if (!file) {
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      if (this.isWrongLimitFileSize(file) || this.isWrongFileFormat(file)) {
        this.$alertMsg("fail", this.$t("validate.upload-info"));
        return;
      }
      try {
        const { Photo } = await AssetRepository.upload(formData);
        await UserRepository.UpdateAvatar({ Avatar: Photo });
        this.$store.commit("Auth/updateCurrentUser", {
          ...this.currentUser,
          Avatar: Photo,
        });
        this.$alertMsg("success", this.$t("swal.success.saved"));
      } catch (e) {
        this.$alertMsg("fail", e.message);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.headers__logo__img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.info {
  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #43464e;
    margin-top: 24px;
  }
  &__header {
    .info__avatar {
      height: 80px;
      width: 80px;
      position: relative;
      border-radius: 50%;
      -webkit-box-shadow: 1px 1px 15px -5px #000;
      box-shadow: 1px 1px 15px -5px #000;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
      }
      .upload-button {
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: pointer;
        //opacity: 0;
        background: #ffffff;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          opacity: 0.9;
        }
        i {
          font-size: 12px;
        }
      }
    }
    .not-verified {
      background: rgba(233, 96, 88, 0.2);
      border-radius: 20px;
      padding: 8px 12px 8px 8px;
      height: 40px;
      color: #e96058;
      i {
        font-size: 24px;
        color: #e96058;
        margin-right: 8px;
      }
      span {
        font-weight: 400;
        font-size: 14px;
      }
      &:hover {
        text-decoration: none;
        background: antiquewhite;
      }
    }
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      .info__name {
        display: inline-block;
        margin: 10px 0;
        text-align: center;
      }
    }
  }
  form.mt-3 {
    @media only screen and (max-width: 768px) {
      .row {
        flex-direction: column;
        margin-top: 0 !important;
        .theme-light {
          margin-bottom: 20px;
        }
      }
    }
  }
  label {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
    color: #43464e;
  }
}
.profile {
  &_section {
    &-title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #43464e;
    }
    &-card {
      height: calc(100% - 44px);
    }
  }
}
.text-elipssis {
  display: block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media only screen and (max-width: 768px) {
    max-width: 80px;
  }
}
</style>
